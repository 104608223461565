import React from 'react';
import Register from '../Register';

const index = () => {
  return (
    <div>
      <Register />
    </div>
  );
};

export default index;
