import React from 'react'
import Navbar from '../Navbar'
import Sub from '../Sub'
import '../../App.css'
import CartModal from '../CartModal'
const index = () => {
  return (
    <div className="subpg_wrapper">
      <Navbar/>
      <Sub/>
    </div>
  )
}

export default index